html {
  @apply bg-gray-100;
}

:root {
  --amplify-primary-color: #d71440;
  --amplify-primary-tint: #ba112d;
  --amplify-primary-shade: #ba112d;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 80vh;
}

.btn {
  @apply py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium cursor-pointer;
}

.btn-success {
  @apply text-white hover:bg-green-600 bg-green-500;
}

.btn-primary {
  @apply text-white hover:bg-pol-red-dark bg-pol-red;
}

.btn-clear {
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50;
}

.terminal-table tr:nth-of-type(even) {
  @apply bg-gray-50;
}

.terminal-table td {
  @apply px-6 py-3 whitespace-nowrap text-sm text-gray-800;
}

.terminal-table th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}
